import domReady from '@roots/sage/client/dom-ready';

// import Bootsrap JS
import 'bootstrap';

// import Swiper JS
import Swiper from 'swiper';
import {Pagination, Autoplay, EffectFade } from 'swiper/modules';
Swiper.use([Pagination, Autoplay, EffectFade]);

/**
 * Application entrypoint
 */
domReady(async () => {
  document.addEventListener("DOMContentLoaded", function () {
    var header = document.getElementById("header");
  
    window.addEventListener("scroll", function () {
        if (header && window.scrollY > 0) {
            header.classList.add("has-scroll");
        } else if (header) {
            header.classList.remove("has-scroll");
        }
    });
  });
  
  // init Swiper:
  const swiper = new Swiper('.swiper.banner-homepage', {
    // Optional parameters
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    // Autoplay settings
    autoplay: {
      delay: 5000, // Delay between transitions in milliseconds
      disableOnInteraction: false, // Enable autoplay even if the user interacts with the slider
    },
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    }
  });
  
  document.addEventListener('DOMContentLoaded', function() {
    const scrollToServicesLink = document.querySelector('.scroll-to-services');
  
    scrollToServicesLink.addEventListener('click', function(event) {
      event.preventDefault();
  
      const servicesSection = document.getElementById('services');
  
      if (servicesSection) {
        // Adjust the offset based on your fixed navigation bar's height
        const offset = 450; // You may need to adjust this value
  
        const offsetTop = servicesSection.getBoundingClientRect().top + window.scrollY;
        const scrollTo = offsetTop - offset;
  
        window.scrollTo({
          top: scrollTo,
          behavior: 'smooth'
        });
      }
    });
  });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

